/* StatusCompletedDocumentos.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    gap: 1rem;
}

.icon {
    font-size: 45px;
    color: #CB0000;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    text-align: left;
}

.message {
    font-size: 1rem;
    color: #737373;
    text-align: left;
    font-weight: 500;
}

.documentName {
    display: block;
    color: #fff;
    font-weight: 500;
}

.thankYou {
    font-size: 1rem;
    color: #fff;
    text-align: left;
    font-weight: 500;
}

.submitButton {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: 3px solid #39393B;
    border-radius: 10px;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-top: 30px;
    height: fit-content;
    background-color: #27272A !important;
}

.submitButton:hover {
    background-color: #39393B !important;
    color: white;
    height: fit-content;
    border: 3px solid #39393B;
}

.separator {
    border: none;
    border-top: 2px solid #737373; /* Ajuste a cor para maior contraste */
    margin: 1rem 0; /* Espaçamento acima e abaixo */
    width: 100%;
}

.divs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.divs div{
    text-align: left;
}