.acceptButton {
    background-color: #1A8700;
    color: white;
    border: none;
    font-weight: 700;
    font-size: 0.9rem;
    height: 100%;
}

.acceptButton:hover {
    background-color: #146500; /* Cor ao passar o mouse */
    color: #fff !important;
}

.spinnerDark {
    font-size: 24px;
    color: #000 !important; /* Cor para o loader no modo escuro */
}

.spinnerWhite {
    font-size: 24px;
    color: #000 !important; /* Cor para o loader no modo claro */
}


.containButton{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 1023px) {
    .containButton{
        display: flex;
        justify-content: center;
    }
}

.acceptDiv{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (max-width: 1023px) {
    .acceptDiv{
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        flex-direction: column;
    }
}

.acceptDiv p{
    text-align: start;
    font-size: 11px;
}

.otherProposal{
    color: #F8A81B;
    font-weight: 600;
}

.acceptedProposal{
    color: #24BE00;
    font-weight: 600;
    text-align: end;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 1023px) {
    .card{
        align-items: flex-start;
    }
    
}

.card a{
    text-decoration: underline;
    font-size: 0.9rem;
    font-weight: 600;
    color: #818181;
}

.modalDark .ant-modal .ant-modal-content {
    background-color: #27272A !important; /* Dark theme background */
    color: #ffffff;
    border-radius: 8px;
}

.modalLight .ant-modal .ant-modal-content {
    background-color: #ffffff !important; /* Light theme background */
    color: #333333;
    border-radius: 8px;
}