.cardDark {
    background-color: #27272A;
    display: grid;
    color: white;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.darkTitle {
    font-size: 1.25rem; /* text-lg */
    font-weight: 500;
    color: #fff;
}

.whiteTitle {
    font-size: 1.25rem; /* text-lg */
    font-weight: 500;
    color: #000;
}

.separator {
    border: none;
    border-top: 1px solid #d1d5db; /* light gray color */
}

.tabsContainer {
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
}

.tabLabel {
    font-size: 1rem;
    color: #333333;
    font-weight: 500;
}

.tabLabelEmpresa {
    font-size: 1rem;
    color: #333333;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.validationMessage {
    font-size: 0.875rem; /* font-sm */
    color: #737373; /* text-neutral-500 */
    font-weight: 600;
    text-align: center;
    /* margin-top: 16px; */
}
