.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Centraliza verticalmente o loader */
}

.spinnerDark {
    font-size: 24px;
    color: #fff !important; /* Cor para o loader no modo escuro */
}

.spinnerWhite {
    font-size: 24px;
    color: #000 !important; /* Cor para o loader no modo claro */
}
