/* StatusPendingDocumentos.module.css */
.grid {
    gap: 1rem;
}
.iconStyle {
    display: flex;
    align-items: center;
    font-size: 1rem;
}
.submitButton {
    background-color: #1A8700;
    color: white;
    border: none;
    font-weight: 700;
    font-size: 0.9rem;
    height: 100%;
    padding: 1rem;
    margin-top: 20px;
    font-size: 1rem;
}

.submitButton:hover {
    background-color: #146500 !important; /* Cor ao passar o mouse */
    color: #fff !important;
}



.form{
    width: 100%;
    display: grid;
    gap:0.5rem
}
