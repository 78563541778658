.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #ffffff;
    padding: 1rem;
}

@media (min-width: 1024px) {
    .container {
        padding: 0 1rem 1rem 1rem;
    }
}

.gridContainer {
    width: 100%;
    display: grid;
    gap: 1rem;
}

.flexRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (min-width: 1024px) {
    .flexRow {
        flex-direction: row;
    }
}

.sectionMain {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sectionCarDetails {
    width: 100%;
    flex: 3;
}

.sectionCalculation {
    width: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

