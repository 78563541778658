/* Cartões para temas escuro e claro */
.cardDark {
    background-color: #27272A;
    display: grid;
    color: #ffffff;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

/* Títulos e textos no tema escuro */
.darkTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
}

.resultadoFinal {
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 16px;
}

.resultadoFinal{

}