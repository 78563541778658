.cardDark {
    background-color: #27272A;
    display: grid;
    color: #ffffff;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonRow {
    display: flex;
    gap: 8px;
}

.whatsAppButton {
    display: flex;
    gap: 8px;
    height: 100%;
    background-color: #277945;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
}

.emailButton {
    display: flex;
    gap: 8px;
    height: 100%;
    background-color: #345791;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
}

.copyButton {
    display: flex;
    gap: 8px;
    height: 100%;
    background-color: #161616;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
}

.icon {
    font-size: 1.25rem; /* Adjusts icon size */
}


.whatsAppButton:hover,
.emailButton:hover,
.copyButton:hover {
    color: #fff !important;
}