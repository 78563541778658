/* Contêiner principal */
.errorContainer {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: 1rem;
    max-width: 1360px;
    margin: 0 auto;
}

/* Layouts para dispositivos */
.mobileLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}

.desktopLayout {
    display: grid;
    grid-template-columns: 5fr 5fr;
    gap: 1rem;
}

/* Estilos da seção de logo e título */
.logoSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

.desktopSpacing {
    align-items: baseline;
    justify-content: center;
}

.logo {
    width: 12.5rem;
    max-width: 12.5rem;
    height: auto;
    object-fit: contain;
    cursor: pointer;
}

.title {
    font-size: 2rem;
    text-align: center;
    max-width: 700px;
    text-align: left;
    font-weight: 700;
    line-height: 40px;
}

@media (max-width: 1023px) {
    .title {
        font-size: 1.7rem;
    }
}

/* Seção de estatísticas */
.statsSection {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.statsRow {
    display: flex;
    gap: 1rem;
}

.card {
    text-align: center;
}

.statNumber {
    font-size: 1.5rem;
    font-weight: 700;
}

.statText {
    font-size: 0.625rem;
}

.stars {
    color: #EC942C;
    /* Amarelo para as estrelas */
}

/* Seção de mensagem de erro */
.messageSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardDark {
    display: flex;
    background-color: #27272A;
    color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: start;
    min-height: 700px;
    align-items: center;
}

.cardDarkPeq {
    background-color: #27272A;
    color: white;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid #FFFFFF1A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardLight {
    display: flex;
    background-color: #ffffff;
    color: #000;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: start;
    min-height: 700px;
    align-items: center;
}

.cardLightPeq {
    background-color: #ffffff;
    color: white;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid #FFFFFF1A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1023px) {
    .cardDark {
        min-height: auto;
    }

    .cardLight {
        min-height: auto;
    }
}

.errorHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
}

.errorCode {
    font-size: 3.688rem;
    font-weight: 700;
}

.errorMessage {
    font-size: 1.75rem;
    font-weight: 700;
}

.errorDescription {
    /* margin: 1rem 0; */
    font-size: 1.25rem;
    color: #B5B5B5;
}

@media (max-width: 1023px) {
    .errorCode {
        font-size: 2.5rem;
        font-weight: 700;
    }

    .errorMessage {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .errorDescription {
        /* margin: 1rem 0; */
        font-size: 1rem;
        color: #B5B5B5;
    }
}

.websiteLink {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: 3px solid #39393B !important;
    border-radius: 10px;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-top: 30px;
    height: 100%;
}

.websiteLink:hover {
    background-color: #39393B;
    color: white;
}

.icon {
    font-size: 45px;
    color: #CB0000;
}

@media (max-width: 1023px) {
    .icon {
        font-size: 30px;
        color: #CB0000;
    }
}