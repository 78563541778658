/* StatusCompletedDocumentos.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    gap: 1rem;
}

.icon {
    font-size: 45px;
    color: #F8A81B;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
}

.message {
    font-size: 1rem;
    color: #737373;
    text-align: left;
    font-weight: 500;
}

.message span{
    color: #fff;
    font-weight: 500;
}

.thankYou {
    font-size: 1rem;
    color: #fff;
    text-align: left;
    font-weight: 500;
}
