/* Container principal */
.appContainer {
    max-width: 1360px;
    margin: 0 auto;
    /* padding: 10px 10px 25px 10px; */
    display: grid;
    gap: 1rem;
}

/* Tema escuro aplicado ao body */
.darkThemeBg {
    background-color: #121212; /* Cor de fundo para o modo escuro */
    color: white; /* Cor do texto para contraste */
}

/* Tema claro aplicado ao body */
.whiteThemeBg {
    background-color: #F7F7F7; /* Cor de fundo para o modo claro */
    color: black; /* Cor do texto para contraste */
}

/* Estilos de tema no App */
.darkTheme {
    color: white;
}

.lightTheme {
    color: black;
}
