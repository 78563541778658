/* Classes gerais */
.card {
    display: grid;
    gap: 1rem;
    align-content: start;
}

.grid {
    display: grid;
}

.gap4 {
    gap: 1rem;
}

.contentStart {
    align-content: start;
}

.flex {
    display: flex;
}

.flexColumn {
    flex-direction: column;
}

.itemsCenter {
    align-items: center;
}

.justifyBetween {
    justify-content: space-between;
}

.gap3 {
    gap: 0.75rem;
}

.gap4 {
    gap: 1rem;
}

.gap7 {
    gap: 1.75rem;
}

.flexRow {
    flex-direction: row;
}

/* Estilos para temas */
.textBlack {
    color: black;
}

.textWhite {
    color: white;
}

.darkButton {
    background-color: black;
    color: white;
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.lightButton {
    background-color: white;
    color: black;
    border-radius: 0.25rem;
    padding: 0.5rem;
}

/* Outras classes */
.fullWidth {
    width: 100%;
}

.logo {
    width: 10rem;
    max-width: 10rem;
    height: 2rem;
    object-fit: contain;
    cursor: pointer;
}

.textXs {
    font-size: 1rem;
}

@media (max-width: 1023px) {
    header{
        padding: 1rem 1rem 0 1rem;
    }
}