/* .cardContainer {
    display: grid;
    gap: 16px;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */


.cardDark {
    background-color: #27272A;
    display: grid;
    color: white;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.darkTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
}

.whiteTitle {
    font-size: 1.25rem; /* text-lg */
    font-weight: 500;
    color: #000;
}

.separator {
    border: none;
    border-top: 1px solid #d1d5db; /* light gray color */
}

.infoGrid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2,minmax(0,1fr));
    align-items: start;
}

.infoItem {
    display: grid;
    gap: 8px;
    color: #333333;
}

.label {
    font-size: 0.875rem; /* text-sm */
    color: #737373; /* text-neutral-500 */
    font-weight: 600;
}

.darkValue {
    font-size: 1rem;
    color: #fff;
    font-weight: 5500;
}

.whiteValue {
    font-size: 1rem;
    color: #000;
    font-weight: 500;
}
