.container {
    display: grid;
    gap: 16px;
    /* align-items: center;
    text-align: center; */
}

.icon {
    font-size: 48px;
    color: #0D9F57;
}

.title {
    font-size: 1.125rem;
    font-weight: bold;
}

.divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 16px 0;
}

.acceptButton {
    background-color: #1A8700;
    color: white;
    border: none;
    font-weight: 700;
    font-size: 0.9rem;
}

.acceptButton:hover {
    background-color: #146500; /* Cor ao passar o mouse */
    color: #fff !important;
}

.textWhite {
    color: #ffffff !important;
}


.acceptDiv{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (max-width: 1023px) {
    .acceptDiv{
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        flex-direction: column;
    }
}

.acceptDiv p{
    text-align: start;
    font-size: 11px;
}