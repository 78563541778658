/* Estilos gerais */
.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 600;
    font-size: 1.125rem;
    /* text-lg */
  }
  
  .estimateBox {
    display: grid;
    gap: 16px;
  }
  
  .estimateRow {
    display: flex;
    justify-content: space-between;
  }
  
  /* Cartões para temas escuro e claro */
  .cardDark {
    background-color: #27272A;
    display: grid;
    color: #ffffff;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
  }
  
  .cardLight {
    background-color: #CFCFCF;
    color: #000000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
  }
  
  /* Títulos e textos no tema escuro */
  .darkTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
  }
  

  .sectionHeaderDark{
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
}

.sectionHeaderLight{
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
}
  
  .grid {
    display: grid;
    gap: 16px;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .text-lg {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .font-normal {
    font-weight: 400;
  }
  
  .hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 8px 0;
  }
  
  .grid-cols-1 {
    grid-template-columns: 1fr;
  }
  
  .grids {
    grid-template-columns: repeat(2, 1fr);
  }
  

  
  .gap-4 {
    gap: 16px;
  }
  
  .gap-x-10 {
    column-gap: 40px; /* Espaçamento horizontal */
  }
  
  .gap-y-2 {
    row-gap: 8px; /* Espaçamento vertical */
  }
  
  @media (max-width: 1023px) {
    .grids {
      grid-template-columns: repeat(1, 1fr);
    }
    .gap-4 {
        gap: 5px;
      }
      .gap-x-10 {
        column-gap: 40px; /* Espaçamento horizontal */
      }
      
      .gap-y-2 {
        row-gap: 20px; /* Espaçamento vertical */
      }
  }

  .text-sm {
    font-size: 0.875rem;
  }
  