/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
  color: #000 !important;
}

.ant-drawer .ant-drawer-header{
  border:0px !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color:#f0f0f0;
}

.ant-slider .ant-slider-rail{
  background-color:#121212 !important;
}

.ant-slider .ant-slider-track{
  background-color:#fff !important;
}

.ant-slider .ant-slider-handle::after{
  box-shadow: 0 0 0 2px #c0c0c0;
}

.ant-slider .ant-slider-handle:hover::after, .ant-slider .ant-slider-handle:active::after, .ant-slider .ant-slider-handle:focus::after{
  box-shadow: 0 0 0 4px #c0c0c0;
}

/* .ant-btn{
  border: 0px !important; 
} */

body {
  /* background: linear-gradient(45deg, #1f1f1f, #090909); */
  font-family: 'Montserrat', sans-serif;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name{
  white-space: normal !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item{
  margin-top: 20px !important;
}

/* .icon-style svg{
  color: #000 !important;
}  */

.form-de-envio-docs{
  display: grid;
  gap: 1rem;
  grid-gap: 1rem;
}

.form-de-envio-docs .ant-form-item{
  margin: 0px !important;
}

.altura-obrg{
  height: 100%;
  min-height: 245px;
}

.com-bg{
  background-color: #000000;
}

.dark-theme{
  background-color: #000000;
}

.light-theme{
  background-color: #ffffff;
}


.header-func .img-user-logo {
  max-width: 90% !important;
  border-radius: 50% !important
}

.historico-pesquisas .ant-image-preview-img {
  border-radius: 0px !important;
}

.ant-select-selection-item,
.ant-input-disabled,
.ant-select-selection-search-input,
.ant-picker-input input {
  color: #000000 !important;
}

.borderless-select .ant-select-selector {
  border: 0px !important
}


* {
  border-color: #575757 !important;
}

.ant-table-row:nth-child(odd) {
  background-color: black !important;
}

.ant-table-row:nth-child(even) {
  background-color: #1c1c1c !important;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: #1c1c1c !important;
}

.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-cell-fix-right {
  background-color: #1c1c1c !important;
}


.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background-color: #1c1c1c !important;
}


.ant-switch {
  background: rgba(147, 147, 147, 0.25) !important;
}

.ant-switch.ant-switch-checked {
  background: #1A8700 !important;
}

.recharts-legend-item-text {
  color: #fff !important;
}

.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 12px !important;
}

.alinhar-centro {
  height: calc(100vh - 45px);
  align-content: center;
  display: flex;
}

.ant-input-prefix .anticon {
  vertical-align: 0px !important;
  color: #000;
}

.anticon {
  vertical-align: 0px !important;
  /* color: #fff; */
}


.media-mercado-nacional .anticon {
  color: #000 !important;
}

.tabela-propostas .anticon {
  color: #000 !important;
}


.ant-pagination .ant-pagination-item a {
  color: #fff;
}

.ant-table-cell {
  /* background:#1c1c1c !important; */
  color: #fff !important;
  padding: 5px 10px !important;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff !important;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #575757 !important;
}

/* .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-tbody >tr >td{
  border-color: #575757 !important;
} */

.ant-pagination-item-active a {
  color: #000 !important;
}


.ant-pagination-item-active {
  border-color: #000 !important;
}

.ant-pagination .ant-pagination-next button,
.ant-pagination .ant-pagination-prev button {
  color: #fff;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #f5f5f5;
  color: #000;
}


.ant-image-mask {
  display: none !important;
}

.ant-image .ant-image-img {
  cursor: pointer;
}

.frase-style {
  max-width: 70%;
  width: 100%;
  text-align: center;
}

.login-section {
  max-width: 400px;
  margin: auto;
  width: 100%;
}

/* .icon-question {
  cursor: pointer;
  background: #f3f3f3;
  padding: 2px;
  border-radius: 5px;
  font-size: 20px;
  color: #737373;
} */


.dark-theme .icon-question {
  background: #fff;
  color: #727272;
  padding: 2px;
}

.light-theme .icon-question {
  background: #ffffff;
  color: #000;
  padding: 2px;
}

.bg-black .icon-question, .icon-question {
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
}


.iconc {
  font-size: 25px;
}

.ant-tabs,
.ant-list-items {
  font-family: 'Montserrat', sans-serif;
}

.dark-theme .card {
  background-color: #1c1c1c;
  padding: 15px;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .dark-theme .card {
    background-color: #1c1c1c;
    padding: 10px;
    border-radius: 5px;
  }
}

.light-theme .card {
  background-color: #CFCFCF;
  padding: 15px;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .light-theme .card {
    background-color: #CFCFCF;
    padding: 10px;
    border-radius: 5px;
  }
}


.swiper-slide img {
  cursor: grab;
}

.card-white {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.menu-item {
  background-color: #000 !important;
  background: var(--dark-primary);
  border-radius: 8px;
  padding: 15px;
}

.tab .ant-collapse-header {
  color: #fff !important;
}


.ant-btn.ant-btn-lg {
  background-color: #3D3D3D;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

/* .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #1c1c1c !important;
  border: 1px solid #fff;
  color: #fff;
} */

.btn-green:hover {
  background-color: transparent !important;
  border: 1px solid #2ae26e !important;
  color: #fff;
}

.btn-blue:hover {
  background-color: transparent !important;
  border: 1px solid #649fff !important;
  color: #fff;
}


.ant-modal-content .anticon {
  color: #000;
}


.link-partilha-sec .icon-question {
  font-size: 28px;
}


.main-content {
  width: 100% !important;
}

@media screen and (max-width: 1023px) {
  .main-content {
    width: 100% !important;
  }

  .swiper-slide img {
    height: 300px !important;
    max-height: 300px;
    height: 100%;
  }

  .frase-style {
    max-width: 100%;
  }
}

.input-error {
  border: 1px solid red;

}

.border-card {
  border: 1px solid #575757;
  padding: 15px;
  border-radius: 5px;
}




.box-func .titulo-form {
  /*margin-top: 15px;*/
  border-bottom: 3px solid #575757;
  margin-bottom: 5px;
}

.titulo-form {
  /*margin-top: 15px;*/
  border-bottom: 3px solid #575757;
  margin-bottom: 25px;
}

.titulo-form p {
  padding-bottom: 7px;
  font-size: 17px;
}



::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 2px #454545;
}

::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}



.swiper-button-next,
.swiper-button-prev {
  transform: scale(.8) !important;
  border-radius: 3px !important;
  background: #161616 !important;
  color: #fff !important;
  height: 40px !important;
  width: 40px !important;
}

.swiper-button-prev:after {
  font-size: 17px !important;
}

.swiper-button-next:after {
  font-size: 17px !important;
}

.custom-collapse-header {
  color: white;
  /* Cor branca para o texto do cabeçalho */
}

.ant-collapse-expand-icon {
  color: white;
  /* Cor branca para o texto do cabeçalho */
}