.cardDark {
    background-color: #27272A;
    display: grid;
    color: #ffffff;
    gap: 1rem;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000000;
    display: grid;
    gap: 1rem;
    padding: 16px;
    border-radius: 8px;
}

.infoGrid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 2rem;
    /* align-items: center; */
    align-items: start;
}

.profileImage {
    border-radius: 50%;
    width: 100%;
    height: auto !important;
}

.textGrid {
    display: grid;
    gap: 5px;
    font-weight: 500;
}

.cardLight .textGrid strong {
    color: #000 !important;
    font-weight: 500;
}

.textGrid strong {
    color: #fff !important;
    font-weight: 500;
}

.textGrid span {
    font-size: 1.25rem;
    font-weight: 600;
}

@media (max-width: 1023px) {
    .textGrid span {
        font-size: 1rem;
    }
}

.textGrid p {
    font-size: 1rem;
    color: #737373;
}

.textGrid {
    display: grid;
    gap: 5px;
}

.buttonGrid {
    display: flex;
    gap: 1rem;
}

.whatsButton {
    background-color: #277945;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    justify-content: center;
    font-size: 1rem;
}

.emailButton {
    background-color: #345791;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    justify-content: center;
    font-size: 1rem;
}

.whatsButton:hover,
.emailButton:hover {
    color: #fff !important;
}