/* Estilos gerais */
.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 600;
    font-size: 1.125rem; /* text-lg */
}

.estimateBox {
    display: grid;
    gap: 16px;
}

.estimateRow {
    display: flex;
    justify-content: space-between;
}

/* Cartões para temas escuro e claro */
.cardDark {
    background-color: #27272A;
    display: grid;
    color: #ffffff;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

/* Títulos e textos no tema escuro */
.darkTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
}

.estimateValueDark {
    font-weight: 500;
    color: #ffffff;
    font-size: 1rem;
}

.infoFinalTooltipDark {
    color: #ffffff;
    font-size: 1rem;
}

/* Títulos e textos no tema claro */
.whiteTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #000000;
}

.estimateValueLight {
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
}

.infoFinalTooltipLight {
    color: #000000;
    font-size: 1rem;
}

/* Imagem */
.imageGallery {
    border-radius: 8px;
    overflow: hidden;
}

.image {
    width: 100%;
    object-fit: cover;
}

/* Grade de informações */
.infoGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    align-items: start;
}

.individualInfo{
    display: grid;
    color: #737373;
    font-weight: 500;
    font-size: 0.875rem;
    gap:8px;
}

.infoTextDark {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 500;
}

.infoText{
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
}

.infoTextLight {
    font-size: 1rem;
    color: #000000;
    font-weight: 500;
}

.sectionHeaderDark {
    font-size: 1.25rem;
    padding-bottom: 8px;
    font-weight: 700;
    color: #ffffff;
}

.sectionHeaderLight {
    font-size: 1.25rem;
    padding-bottom: 8px;
    font-weight: 700;
    color: #000000;
}

.infoItemDark {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-size: 1rem; /* text-sm */
    width: 100%;    
}

.infoItemLight {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-size: 1rem; /* text-sm */
    width: 100%;    
}

.infoItemDark span{
    text-align: end;
    font-weight: 500;
    color: #fff;
}

.infoItemLight span{
    text-align: end;
    font-weight: 500;
    color: #000;
}

.infoItemDark p, .infoItemLight p{
    color: #737373;
    font-weight: 500;
    font-size: 0.875rem;
}

.borderTop {
    border-top: 1px solid #444444;
    padding-top: 8px;
}

.separator {
    border: none;
    border-top: 1px solid #d1d5db; /* cor cinza clara */
}

/* Link */
.link {
    color: #000;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    font-size: 0.875rem;
    text-decoration: underline;
}

.linkDark {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    font-size: 0.875rem;
    text-decoration: underline;
}

.infoTooltip {
    color: #b5b5b5;
    font-size: 1rem;
}

/* Valor final */
.finalValue {
    font-weight: 600;
    color: #269f0a;
    font-size: 1rem;
}

.sectionHeaderDark{
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
}

.sectionHeaderLight{
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
}

.title{
    font-weight: 700;
    font-size: 1.75rem;
    line-break: anywhere;
}

@media (max-width: 1023px) {
    .title{
        font-size: 1.5rem;
        line-height: 30px;
    }
    .infoFinalTooltipDark, .infoTooltipLight, .infoTooltipDark {
        color: #ffffff;
        font-size: 0.8rem;
    }
    .estimateValueDark {
        font-size: 0.8rem;
    }
    .finalValue {
        font-size: 0.8rem;
    }
}

.highlightText {
    color: #F8A81B; /* Cor de exemplo */
}

.highlightTextFinal {
    color: #269f0a; /* Cor de exemplo */
}

.cardLight .highlightText {
    color: #bc811a;
    font-weight: 600;
}