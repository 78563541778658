.cardDark {
    background-color: #27272A;
    color: white;
    gap: 16px;
    padding: 16px;
    border-radius: 0.5rem;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000000;
    gap: 16px;
    padding: 16px;
    border-radius: 0.5rem;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexContainerH {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@media (max-width: 1023px) {
    .flexContainer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 16px;
    }

    .flexContainerH {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .flexContainerH span {
        font-size: 12px;
    }
}

.name {
    font-size: 1.45rem;
    font-weight: 700;
    margin: 0;
}

@media (max-width: 1023px) {
    .name {
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0;
    }
}

.nameDark {
    color: #fff;
    /* Cor para nome no tema escuro */
}

.nameLight {
    color: #000;
    /* Cor para nome no tema claro */
}

.expiryText {
    font-size: 16px;
    font-weight: 500;
    color: #737373;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.iconInfo {
    color: #F8A81B;
    cursor: pointer;
}

.flexContainerChild {
    display: grid;
    gap: 0.5rem;
}