.cardDark {
    background-color: #27272A;
    display: grid;
    color: white;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.cardLight {
    background-color: #CFCFCF;
    color: #000;
    display: grid;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labelContainer {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.textDark{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
}

.textLight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
}

.label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.labelText {
    font-size: 0.9em;
    margin-left: 8px;
}

.iconQuestion {
    font-size: 17px;
    color: #888;
    cursor: pointer;
    margin-left: 4px;
}

.ant-drawer-content .drawerContentDark{
    background: none !important;
}

.ant-drawer-content .drawerContentLight{
    background: none !important;
}

/* Drawer Background and Text */
.drawerContentDark {
    /* background-color: #27272A; */
    color: #fff;
    border-radius: 30px 0px 0px 30px;
}

.drawerContentLight {
    /* background-color: #F7F7F7; */
    color: #333;
    border-radius: 30px 0px 0px 30px;
}

/* Custom Header */
.drawerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
}

/* Title Text */
.drawerTitleDark {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
}

.drawerTitleLight {
    color: #333;
    font-size: 1.25rem;
    font-weight: 600;
}

/* Close Icon */
.closeIcon {
    cursor: pointer;
    font-size: 24px;
    margin-top: -2px;
}

/* Drawer Main Content */
.drawerBody {
    display: grid;
    gap: 25px;
}

.textBase {
    font-size: 1.1rem;
    line-height: 30px;
}
